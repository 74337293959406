import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SEE_MEMBER_SYSTEM_CANCEL_BOOKINGS_QUERY } from "../../graphqls/queries";
import { ButtonSpan } from "../../styles";
import { SiteType } from "../../__generated__/globalTypes";
import {
    seeMemberSystemCancelRegScheduleBookings,
    seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings,
    seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_systemCancelBookings,
} from "../../__generated__/seeMemberSystemCancelRegScheduleBookings";
import { Popup } from "./Popup";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

const Text = styled.span`
    padding: 5px;
    font-size: 13px;
    color: ${(prop) => prop.theme.red};

    @media ${({ theme }) => theme.mobile} {
        font-size: 12px;
    }
`;
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

export const TableContainer = styled.div`
    display: flex;
`;

export const TableCombineDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 70px;
    border-right: 1px solid gray;
    border-top: 2px solid gray;

    @media ${({ theme }) => theme.mobile} {
        width: 90px;
    }
`;

export const TableSingleDiv = styled.div<{
    bottomBorder?: boolean;
    rightBorder?: boolean;
    letfBorder?: boolean;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    border-bottom: ${(props) => (props.bottomBorder === true ? "1px" : "0px")}
        solid gray;
    border-right: ${(props) => (props.rightBorder === true ? "1px" : "0px")}
        solid gray;
    border-left: ${(props) => (props.letfBorder === true ? "1px" : "0px")} solid
        gray;

    @media ${({ theme }) => theme.mobile} {
        width: 90px;
    }
`;

export const TableText = styled.span`
    font-size: 13px;
    text-align: center;
    width: 130px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
        width: 90px;
    }
`;

export const TableButton = styled(ButtonSpan)`
    font-size: 13px;
    text-align: center;
    padding: 7px 10px;
    color: ${(prop) => prop.theme.white};
    background-color: ${(prop) => prop.theme.blue};
    border-radius: 5px;

    @media ${({ theme }) => theme.mobile} {
        font-size: 11px;
    }
`;

interface ISystemCancelRegScheduleBookingPopupProps {
    didClosePopup?: () => void;
}

interface ISystemCancelAffectBooking {
    systemCancelBooking: seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_systemCancelBookings;
    affectBooking?: seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings;
}

export function SystemCancelRegScheduleBookingPopup({
    didClosePopup,
}: ISystemCancelRegScheduleBookingPopupProps) {
    const [systemCancelBookings, setSystemCancelBookings] = useState<
        seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_systemCancelBookings[]
    >([]);
    const [affectBookings, setAffectBookings] = useState<
        seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings[]
    >([]);
    const [systemCancelAffectBookings, setSystemCancelAffectBookings] =
        useState<ISystemCancelAffectBooking[]>([]);

    useEffect(() => {
        const temp: ISystemCancelAffectBooking[] = [];
        if (systemCancelBookings.length > 0 && affectBookings.length > 0) {
            const getAffectBookingById = (id: string) => {
                let result:
                    | seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings
                    | undefined = undefined;
                for (let i = 0; i < affectBookings.length; ++i) {
                    const booking = affectBookings[i];

                    if (booking.id === id) {
                        result = booking;
                        break;
                    }
                }

                return result;
            };

            const getAffectBookingByKey = (key: string) => {
                let result:
                    | seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings
                    | undefined = undefined;
                for (let i = 0; i < affectBookings.length; ++i) {
                    const booking = affectBookings[i];

                    if (booking.key === key) {
                        result = booking;
                        break;
                    }
                }

                return result;
            };

            systemCancelBookings.map((cancel) => {
                let affect:
                    | seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings
                    | undefined = undefined;
                if (cancel.sysCancelCauseBookingId) {
                    affect = getAffectBookingById(
                        cancel.sysCancelCauseBookingId,
                    );
                }

                if (cancel.sysCancelCauseScheduleKey) {
                    affect = getAffectBookingByKey(
                        cancel.sysCancelCauseScheduleKey,
                    );
                }

                temp.push({
                    systemCancelBooking: cancel,
                    affectBooking: affect,
                });
            });
        } else {
            if (systemCancelBookings.length > 0) {
                systemCancelBookings.map((cancel) => {
                    temp.push({
                        systemCancelBooking: cancel,
                    });
                });
            }
        }

        setSystemCancelAffectBookings(temp);
    }, [systemCancelBookings, affectBookings]);

    useQuery<seeMemberSystemCancelRegScheduleBookings>(
        SEE_MEMBER_SYSTEM_CANCEL_BOOKINGS_QUERY,
        {
            onError: (error) => {
                console.log(error);
            },
            onCompleted: (data) => {
                const {
                    seeMemberSystemCancelRegScheduleBookings: {
                        ok,
                        systemCancelBookings,
                        affectBookings,
                    },
                } = data;

                if (ok) {
                    const cancelTemp: seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_systemCancelBookings[] =
                        [];
                    const affectTemp: seeMemberSystemCancelRegScheduleBookings_seeMemberSystemCancelRegScheduleBookings_affectBookings[] =
                        [];
                    systemCancelBookings?.map((booking) => {
                        if (booking) {
                            cancelTemp.push(booking);
                        }
                    });

                    affectBookings?.map((booking) => {
                        if (booking) {
                            affectTemp.push(booking);
                        }
                    });

                    setSystemCancelBookings(cancelTemp);
                    setAffectBookings(affectTemp);
                }
            },
        },
    );

    const getDateStr = (time?: String, addSec?: Boolean) => {
        let result = "";

        if (time) {
            const date = new Date(Number(time));
            result = `${String(date.getMonth() + 1).padStart(2, "0")}-${String(
                date.getDate(),
            ).padStart(2, "0")} ${String(date.getHours()).padStart(
                2,
                "0",
            )}:${String(date.getMinutes()).padStart(2, "0")}`;

            if (addSec) {
                result =
                    result + `:${String(date.getSeconds()).padStart(2, "0")}`;
            }
        }

        return result;
    };

    const siteName = (site: SiteType) => {
        let result = "";

        if (site === SiteType.SC) {
            result = "스페이스클라우드";
        } else if (site === SiteType.NA) {
            result = "네이버";
        }

        return result;
    };

    const onClickRefund = (sysCancelCauseScheduleKey: string, name: string) => {
        const confirm = window.confirm(
            `예약자 "${name}"님의 환불을 진행하시겠습니까??`,
        );

        if (confirm) {
            console.log(sysCancelCauseScheduleKey, name);
        }
    };

    return (
        <Popup didClosePopup={didClosePopup} maxWidth="1000px">
            <Container>
                <TableContainer
                    style={{
                        borderBottom: "2px solid gray",
                        borderTop: "1px solid gray",
                    }}
                >
                    <TableSingleDiv
                        rightBorder={true}
                        letfBorder={true}
                        style={{ width: "80px" }}
                    >
                        <TableText>취소환불</TableText>
                    </TableSingleDiv>
                    <TableSingleDiv rightBorder={true}>
                        <TableText>지점</TableText>
                    </TableSingleDiv>
                    <TableSingleDiv rightBorder={true}>
                        <TableText>예약 시간</TableText>
                    </TableSingleDiv>
                    <TableSingleDiv rightBorder={true}>
                        <TableText>예약자</TableText>
                    </TableSingleDiv>
                </TableContainer>
                {systemCancelAffectBookings.map((cancelAffect, index) => {
                    return (
                        <TableContainer key={index}>
                            <TableSingleDiv
                                bottomBorder={true}
                                rightBorder={true}
                                letfBorder={true}
                                style={{ width: "80px" }}
                            >
                                <TableButton
                                    onClick={() => {
                                        let sysCancelCauseScheduleKey =
                                            cancelAffect.systemCancelBooking
                                                .sysCancelCauseScheduleKey;
                                        let name =
                                            cancelAffect.affectBooking
                                                ?.username;

                                        if (
                                            cancelAffect.systemCancelBooking
                                                .schedule === false
                                        ) {
                                            name =
                                                cancelAffect.systemCancelBooking
                                                    .username;
                                        }
                                        if (sysCancelCauseScheduleKey && name) {
                                            onClickRefund(
                                                sysCancelCauseScheduleKey,
                                                name,
                                            );
                                        }
                                    }}
                                >
                                    환불
                                </TableButton>
                            </TableSingleDiv>
                            <TableSingleDiv
                                bottomBorder={true}
                                rightBorder={true}
                            >
                                <TableText>
                                    {`${cancelAffect.systemCancelBooking.product.franchise.name}(${cancelAffect.systemCancelBooking.product.name})`}
                                </TableText>
                                <TableText style={{ marginTop: "5px" }}>
                                    {cancelAffect.systemCancelBooking.schedule
                                        ? cancelAffect.affectBooking
                                            ? `(${siteName(
                                                  cancelAffect.affectBooking
                                                      .site,
                                              )})`
                                            : "?"
                                        : `(${siteName(
                                              cancelAffect.systemCancelBooking
                                                  .site,
                                          )})`}
                                </TableText>
                            </TableSingleDiv>
                            <TableSingleDiv
                                bottomBorder={true}
                                rightBorder={true}
                            >
                                <TableText>
                                    {cancelAffect.systemCancelBooking.schedule
                                        ? cancelAffect.affectBooking
                                            ? `${getDateStr(
                                                  cancelAffect.affectBooking
                                                      .startDateUTC,
                                              )} ~`
                                            : "?"
                                        : `${getDateStr(
                                              cancelAffect.systemCancelBooking
                                                  .startDateUTC,
                                          )} ~`}
                                </TableText>
                                <TableText style={{ marginTop: "5px" }}>
                                    {cancelAffect.systemCancelBooking.schedule
                                        ? cancelAffect.affectBooking
                                            ? `${getDateStr(
                                                  cancelAffect.affectBooking
                                                      .endDateUTC,
                                              )}`
                                            : "?"
                                        : `${getDateStr(
                                              cancelAffect.systemCancelBooking
                                                  .endDateUTC,
                                          )}`}
                                </TableText>
                            </TableSingleDiv>
                            <TableSingleDiv
                                bottomBorder={true}
                                rightBorder={true}
                            >
                                <TableText>
                                    {cancelAffect.systemCancelBooking.schedule
                                        ? cancelAffect.affectBooking
                                            ? `${cancelAffect.affectBooking.username}`
                                            : "?"
                                        : `${cancelAffect.systemCancelBooking.username}`}
                                </TableText>
                                <TableText style={{ marginTop: "5px" }}>
                                    {cancelAffect.systemCancelBooking.schedule
                                        ? cancelAffect.affectBooking
                                            ? `${cancelAffect.affectBooking.userPhone}`
                                            : "?"
                                        : `${cancelAffect.systemCancelBooking.userPhone}`}
                                </TableText>
                            </TableSingleDiv>
                        </TableContainer>
                    );
                })}
                <TextContainer>
                    <Text>* 스케줄 등록 중에 들어온 예약 내역입니다.</Text>
                    <Text>
                        * 환불이 완료되면 예약자에게 자동으로 문자가 전송됩니다.
                    </Text>
                    <Text>* 환불 완료까지 약 10분 정도 소요됩니다.</Text>
                </TextContainer>
            </Container>
        </Popup>
    );
}
