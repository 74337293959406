import styled from "styled-components";
import routes from "../../routes";
import { ButtonSpan } from "../../styles";
import { openUrlNewTab } from "../../utils";
import { BOARD_CHANGE_EMAIL_INDEX } from "../faq/FAQDetailHeader";
import { Popup } from "./Popup";

interface ILoginSelectorPopup {
    didClosePopup?: () => void;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.span`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 30px;
`;

const Text = styled.span`
    font-size: 15px;
    line-height: 130%;
`;

const TextBold = styled.span`
    font-size: 15px;
    font-weight: 700;
`;

const Enter = styled.span`
    padding: 10px;
`;

const Link = styled(ButtonSpan)`
    font-size: 15px;
    font-weight: 700;
    color: ${(props) => props.theme.blue};
`;

export function LoginSelectorPopup({ didClosePopup }: ILoginSelectorPopup) {
    return (
        <Popup didClosePopup={didClosePopup} maxWidth={"350px"}>
            <Container>
                <Title>로그인방법 변경 불가 안내</Title>
                <Text>
                    스페이스 클라우드의 네이버 및 카카오 로그인은 2차인증 관련
                    문제가 있습니다.
                </Text>
                <Enter />
                <Text>
                    ONBI에 가입을 위해선 스페이스 클라우드 호스트 로그인을{" "}
                    <TextBold>e-mail 로그인</TextBold>으로 변경이 필요합니다.
                </Text>
                <Enter />
                <Text>
                    자세한 사항은{" "}
                    <Link
                        onClick={() => {
                            openUrlNewTab(
                                `${routes.faq}/${BOARD_CHANGE_EMAIL_INDEX}`,
                            );
                        }}
                    >
                        여기
                    </Link>
                    를 참조해주세요.
                </Text>
            </Container>
        </Popup>
    );
}
