import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { LogoFontSpan } from "../../styles";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
`;

const Content = styled.div<{ maxWidth?: string }>`
    background-color: ${(props) => props.theme.white};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 300px;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "500px")};
`;

const ChildContainer = styled.div`
    display: inline-block;
    padding: 25px;
    max-height: 70vh;
    overflow-y: overlay;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.border};
`;

const HeaderLogo = styled(LogoFontSpan)`
    font-size: 22px;
    padding-left: 10px;
    color: ${(props) => props.theme.blue};
`;

const CloseButton = styled.span`
    font-weight: 700;

    user-select: none;
    color: ${(props) => props.theme.black};
    padding: 15px 10px;
    cursor: pointer;
`;

interface PopupProps {
    children: any;
    didClosePopup?: () => void;
    maxWidth?: string;
}

export function Popup({ children, didClosePopup, maxWidth }: PopupProps) {
    const [close, setClose] = useState(false);

    useEffect(() => {
        document.addEventListener("keyup", keyupListener, false);

        return () => {
            document.removeEventListener("keyup", keyupListener);
        };
    }, []);

    useEffect(() => {
        if (close === true) {
            if (didClosePopup) {
                didClosePopup();
            }
        }
    }, [close, didClosePopup]);

    const keyupListener = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setClose(true);
        }
    };

    return (
        <>
            {close === false ? (
                <Container
                    className="BackgroundContainer"
                    onClick={(event) => {
                        const target = event.target as any;
                        if (typeof target.className === "string") {
                            if (
                                target.className.includes("BackgroundContainer")
                            ) {
                                setClose(true);
                            }
                        }
                    }}
                >
                    <Content maxWidth={maxWidth}>
                        <Header>
                            <HeaderLogo>ONBI</HeaderLogo>
                            <CloseButton
                                onClick={() => {
                                    setClose(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faClose} size="1x" />
                            </CloseButton>
                        </Header>
                        <ChildContainer>{children}</ChildContainer>
                    </Content>
                </Container>
            ) : null}
        </>
    );
}
