import styled from "styled-components";
import { Popup } from "./Popup";
import {
    getOnbiLoginInfoDatas,
    selectOnbiLoginInfo,
    signUserOut,
} from "../../apollo";
import { ButtonSpan, lightTheme } from "../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { SignInPopup } from "./SignInPopup";

interface IAccountListPopup {
    didClosePopup?: () => void;
    style?: React.CSSProperties;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ListButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 0px 20px;
    background: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    border-radius: 10px;
`;

const ListButton = styled(ButtonSpan)`
    width: 200px;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid ${(props) => props.theme.white};
    text-align: center;
    font-size: 15px;
`;

export function AccountListPopup({ didClosePopup, style }: IAccountListPopup) {
    const loginDatas = getOnbiLoginInfoDatas();
    const [showSignInPopup, setShowSignInPopup] = useState(false);

    const onClickLogin = (loginId: string, signedIn: boolean) => {
        if (signedIn === false) {
            selectOnbiLoginInfo(loginId);
        }
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <ListButtonContainer>
                    {loginDatas.map(({ loginId, signedIn }, index) => {
                        return (
                            <ListButton
                                disabled={signedIn}
                                key={index}
                                onClick={() => {
                                    onClickLogin(loginId, signedIn);
                                }}
                            >
                                {loginId}
                                {signedIn ? (
                                    <FontAwesomeIcon
                                        style={{ marginLeft: "5px" }}
                                        icon={faCheckCircle}
                                        color={lightTheme.white}
                                        size="xs"
                                    />
                                ) : null}
                            </ListButton>
                        );
                    })}
                    <ListButton
                        onClick={() => {
                            setShowSignInPopup(true);
                        }}
                        style={{ borderBottom: "0px" }}
                    >
                        다른 계정 추가
                    </ListButton>
                </ListButtonContainer>
                <ListButton
                    style={{ borderBottom: "0px" }}
                    onClick={signUserOut}
                >
                    모든 계정 로그아웃
                </ListButton>
            </Container>
            {showSignInPopup ? (
                <SignInPopup
                    didClosePopup={() => {
                        setShowSignInPopup(false);
                    }}
                />
            ) : null}
        </Popup>
    );
}
