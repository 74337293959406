import styled from "styled-components";
import { ButtonSpan, lightTheme } from "../../styles";
import { Popup } from "./Popup";

interface IConsultingPrivacyPopup {
    didClosePopup?: () => void;
    didSelectOkButton: (ok: boolean) => void;
}

const Container = styled.div`
    width: 400px;
`;

const Border = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 5px;
    margin: 20px;
    padding: 20px;
`;

const Title = styled.span`
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 20px;
`;

const Text = styled.span`
    margin: 5px;
    color: ${(props) => props.theme.fontDarkGray};
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
    display: flex;
    height: 40px;
    justify-content: space-around;
`;

const Button = styled(ButtonSpan)`
    display: flex;
    width: 40%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    color: ${(props) => props.theme.white};
    border-radius: 5px;
`;

export function ConsultingPrivacyPopup({
    didClosePopup,
    didSelectOkButton,
}: IConsultingPrivacyPopup) {
    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <Border>
                    <Title>상담에 필요한 개인정보 수집 및 이용동의</Title>
                    <Text>- 수집 항목: 성함, 휴대폰 번호</Text>
                    <Text>- 수집 및 이용 목적: 온비 가입 상담</Text>
                    <Text>- 보관 및 이용기간: 이용 목적 달성 시</Text>
                </Border>
                <ButtonContainer>
                    <Button
                        style={{ backgroundColor: lightTheme.blue }}
                        onClick={() => {
                            didSelectOkButton(true);
                        }}
                    >
                        수집 동의
                    </Button>
                    <Button
                        style={{ backgroundColor: lightTheme.red }}
                        onClick={() => {
                            didSelectOkButton(false);
                        }}
                    >
                        취소
                    </Button>
                </ButtonContainer>
            </Container>
        </Popup>
    );
}
