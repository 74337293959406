import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import styled from "styled-components";
import { evictCacheRootQuery } from "../../global";
import { UPLOAD_FILES_MUTATION } from "../../graphqls/mutations";
import { SEE_MEMBER_FILES_QUERY } from "../../graphqls/queries";
import useMember from "../../hooks/useMember";
import { ButtonSpan } from "../../styles";
import { openUrlNewTab } from "../../utils";
import {
    awsUploadFiles,
    awsUploadFilesVariables,
} from "../../__generated__/awsUploadFiles";
import { OnbiFileType, UploadProp } from "../../__generated__/globalTypes";
import {
    seeMemberFiles,
    seeMemberFilesVariables,
} from "../../__generated__/seeMemberFiles";
import { Popup } from "./Popup";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Text = styled.span`
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.black};
    margin-top: 3px;
`;

const TextRed = styled.span`
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.red};
    margin-top: 3px;
`;

const Button = styled(ButtonSpan)`
    display: flex;
    flex-direction: column;
    font-weight: 700;
    justify-content: center;
    align-items: center;
`;
const ButtonSubText = styled.span`
    font-size: 13px;
    font-weight: 400;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 40px;
`;

interface IPhoneCertPopupProps {
    didClosePopup?: () => void;
}

export const uploadFiles = (
    willUploadFiles: FileList,
    uploadFilesMutation: any,
) => {
    const uploadProps: UploadProp[] = [];

    for (let i = 0; i < willUploadFiles.length; ++i) {
        const file = willUploadFiles.item(i);

        if (file) {
            uploadProps.push({
                file,
                size: file.size,
            });
        }
    }

    if (uploadProps.length > 0) {
        uploadFilesMutation({
            variables: {
                fileProps: uploadProps,
                fileType: OnbiFileType.PHONE_CERT_FILE,
            },
        });
    }
};

export const onClickUploadFiles = (
    memberFileCount: number,
    uploadFilesMutation: any,
) => {
    let ok = true;
    if (memberFileCount > 0) {
        ok = window.confirm(
            "이미 전송된 파일이 있습니다.\n다시 전송하게되면 기존 파일은 제거됩니다.",
        );
    }

    if (ok) {
        var input = document.createElement("input");
        input.type = "file";
        input.accept = "image/gif, image/jpeg, image/png, application/pdf";
        input.multiple = true;
        input.click();
        input.onchange = ({ target: { validity, files } }: any) => {
            if (validity.valid === true) {
                const willUploadFiles = files as FileList;
                const message = `선택하신 ${willUploadFiles.length}개의 파일을 전송하시겠습니까?`;
                const ok = window.confirm(message);

                if (ok) {
                    uploadFiles(willUploadFiles, uploadFilesMutation);
                }
            }
        };
    }
};

export function PhoneCertPopup({ didClosePopup }: IPhoneCertPopupProps) {
    const [memberFileCount, setMemberFileCount] = useState<
        number | undefined
    >();

    const me = useMember();

    useQuery<seeMemberFiles, seeMemberFilesVariables>(SEE_MEMBER_FILES_QUERY, {
        onCompleted: (data) => {
            const {
                seeMemberFiles: { ok, memberFiles },
            } = data;

            if (ok) {
                if (memberFiles) {
                    setMemberFileCount(memberFiles.length);
                }
            }
        },
        variables: {
            fileType: OnbiFileType.PHONE_CERT_FILE,
        },
    });

    const [uploadFilesMutation] = useMutation<
        awsUploadFiles,
        awsUploadFilesVariables
    >(UPLOAD_FILES_MUTATION, {
        onCompleted: (data: awsUploadFiles) => {
            const {
                awsUploadFiles: { ok, error },
            } = data;

            if (ok) {
                alert("인증서류가 전송되었습니다.");
                evictCacheRootQuery();
            } else {
                alert(error);
            }
        },
    });

    const certExpiryDate = () => {
        let result = "아직 인증되지않은 사용자";

        if (me?.memberCertification?.phoneCertExpiryUTC) {
            const date = new Date(
                Number(me.memberCertification.phoneCertExpiryUTC),
            );
            result = `${date.getFullYear()}-${String(
                date.getMonth() + 1,
            ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        }

        return result;
    };

    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <Text>{`전화번호 인증 만료일: ${certExpiryDate()}`}</Text>
                <Text style={{ marginTop: "10px" }}>
                    만료일이 남았더라도 ONBI 에서 안내문자를 받으셨거나
                </Text>
                <Text>한번도 인증서류를 전달한 적이 없으신 경우</Text>
                <TextRed>꼭 인증서류를 제출해 주시기바랍니다.</TextRed>

                <Text style={{ marginTop: "10px" }}>
                    Web발신 문자서비스(ONBI 자동문자 서비스)의 실명 인증
                    도입으로 인해
                </Text>
                <Text>
                    로그인한 회원의명의자 확인과 등록된 사업자와의 관계 증명이
                    반드시 필요하게 되었습니다.
                </Text>
                <Text>
                    그래서 ONBI 기존 가입자 분들과 신규 가입자 분들은 해당
                </Text>
                <Text>통신사의 발신번호 증빙서류가 필요합니다.</Text>
                <Text>
                    또한 문자 자동화 서비스 위임을 위해 추가 서류가 필요합니다.
                </Text>
                <TextRed style={{ marginTop: "10px" }}>
                    필요 서류(4종): 통신사의 발신번호 증빙서류,
                </TextRed>
                <TextRed>신분증사본(주민번호뒷자리 X), 위-수탁계약서,</TextRed>
                <TextRed>위임관계증명서</TextRed>
                <Text style={{ marginTop: "10px" }}>
                    - 관련 법령: 「거짓으로 표시된 전화번호로 인한 이용자의 피해
                    예방 등에 관한 고시」 개정(2022년 3월 2일)
                </Text>
                <ButtonContainer>
                    <Button
                        onClick={() =>
                            openUrlNewTab(
                                "https://blog.naver.com/onbi114/222824425132",
                            )
                        }
                    >
                        인증서류 확인
                    </Button>
                    <Button
                        onClick={() => {
                            if (memberFileCount !== undefined) {
                                onClickUploadFiles(
                                    memberFileCount,
                                    uploadFilesMutation,
                                );
                            }
                        }}
                        disabled={memberFileCount === undefined}
                    >
                        {memberFileCount === 0
                            ? "인증서류 전송"
                            : "인증서류 변경"}
                        <ButtonSubText>(복수 선택 가능)</ButtonSubText>
                    </Button>
                    <Button
                        onClick={() =>
                            openUrlNewTab(
                                "https://www.msit.go.kr/bbs/view.do?sCode=user&mId=108&mPid=103&pageIndex=&bbsSeqNo=83&nttSeqNo=3175540&searchOpt=ALL&searchTxt=",
                            )
                        }
                    >
                        관련 법령 보기
                    </Button>
                </ButtonContainer>

                <TextRed>
                    * 인증서류 확인 작업은 업무일 기준 최대 72시간이 소요됩니다.
                </TextRed>
                <TextRed>
                    * 전화번호 인증 만료일 전이라면 문자 서비스를 계속 이용하실
                    수 있습니다.
                </TextRed>
                <TextRed>
                    * 인증서류를 한번도 제출하지 않은경우 만료일이 남았더라도
                    문자 전송이 안될수도 있습니다.
                </TextRed>
            </Container>
        </Popup>
    );
}
