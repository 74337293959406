export interface KeyValues {
    key: string;
    value: string;
}

export function makeSameKeyValueList(keys: string[]) {
    let result: Array<KeyValues> = [];
    keys.map((key) => {
        result.push({
            key,
            value: key,
        });
    });

    return result;
}

export function openUrlNewTab(url: string) {
    window.open(url, "_blank")?.focus();
}

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export const phoneHyphen = (target: string) => {
    const result = target
        .replace(/[^0-9, *]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    return result;
};

export const isIOS = () => {
    let result = false;
    const agent = navigator.userAgent.toLowerCase();

    if (agent.indexOf("android") > -1) {
        // 안드로이드인 경우
    } else if (
        agent.indexOf("iphone") > -1 ||
        agent.indexOf("ipad") > -1 ||
        agent.indexOf("ipod") > -1
    ) {
        result = true;
        // IOS인 경우
    } else {
        // 기타
    }

    return result;
};
