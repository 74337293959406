const routes = {
    home: "/",
    consulting: "/consulting",
    signup: "/signup",
    signin: "/signin",
    privacy: "/privacy",
    terms: "/terms",
    saleItems: "/sale-items",
    console: "/console/*",
    faq: "/faq/detail/*",
};

export const consoleRoutes = {
    bookings: "/console/bookings",
    bookingsCalendar: "/console/bookings/calendar",
    bookingsDay: "/console/bookings/day",
    messages: "/console/messages",
    message_setting: "/console/message-setting",
    statistics_bookings: "/console/statistics-bookings",
    statistics_bookings_calendar: "/console/statistics-bookings/calendar",
    statistics_bookings_day: "/console/statistics-bookings/day",
    statistics_messages: "/console/statistics-messages",
    change_member_password: "/console/change-member-password",
    update_hide_product: "/console/update-hide-product",
};

export default routes;
