import styled from "styled-components";
import { Popup } from "./Popup";

interface ITextPopup {
    text: string;
    didClosePopup?: () => void;
    style?: React.CSSProperties;
}

const Container = styled.div``;

const TextArea = styled.textarea`
    width: 300px;
    height: 450px;
    outline: none;
`;

export function TextPopup({ text, didClosePopup, style }: ITextPopup) {
    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <TextArea readOnly value={text} style={style} />
            </Container>
        </Popup>
    );
}
